import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import {
	ExclamationMarkTriangularSvgComponent,
	SearchSvgComponent,
} from '@uc/shared/svg';

@Component({
	selector: 'uc-table-search',
	standalone: true,
	imports: [ExclamationMarkTriangularSvgComponent, SearchSvgComponent],
	templateUrl: './table-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSearchComponent {
	heading = input.required<string>();
	resultCount = input.required<number>();
	resultType = input.required<string>();
	errorTitle = input.required<string>();
	errorDescription = input.required<string>();
	placeholder = input('Search subjects');

	onSearch = output<string>();

	onSubmit(e: Event) {
		e.preventDefault();
	}
}
